/*
 * @Author: hwu
 * @Date: 2024-02-27 14:48:05
 * @Description: file content
 * @LastEditTime: 2024-02-29 10:17:35
 */

import store from '../store'

// 设置 rem 函数
function setRem() {
  // 基准大小
  const baseSize = 24
  // 当前页面宽度相对于 1920 宽的缩放比例，可根据自己需要修改
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  // 把屏幕宽度塞入缓存，其他地方要用
  store.dispatch('common/updateScreenWidthVuex', htmlWidth)

  if (htmlWidth > 1280) {
    htmlWidth = 1280
  } else if (htmlWidth < 512) {
    htmlWidth = 512
  }

  const scale = htmlWidth / 1920

  const fontSize = baseSize * Math.min(scale, 2)
  // 得到html的Dom元素
  const htmlDom = document.getElementsByTagName('html')[0]
  // 设置根元素字体大小
  htmlDom.style.fontSize = fontSize + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = () => {
  return (() => {
    setRem()
  })()
}
