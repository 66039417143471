const user = {
  namespaced: true,
  state: {
    userId: '',
    userDialogShow: false
  },
  mutations: {
    setUserDialogShow: (state, data) => {
      state.userDialogShow = data
    }
  },
  actions: {
    updateUserDialogShow({ commit }, userDialogShow) {
      commit('setUserDialogShow', userDialogShow)
    }
  }
}
export default user
