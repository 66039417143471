const common = {
  namespaced: true,
  state: {
    screenWidth: 1920
  },
  mutations: {
    SET_SCREEN_WIDTH: (state, data) => {
      state.screenWidth = data
    }
  },
  actions: {
    updateScreenWidthVuex({ commit }, width) {
      commit('SET_SCREEN_WIDTH', width)
    }
  }
}
export default common
