/*
 * @Author: hwu
 * @Date: 2021-02-26 09:44:28
 * @Description: file content
 * @LastEditTime: 2024-02-22 11:37:56
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home/Home.vue'),
    meta: { title: '威弘科技' }
  },
  {
    path: '/download/app',
    name: 'DownApp',
    component: () => import('@/views/DownloadApp.vue'),
    meta: { title: '下载企业悠订App' }
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
