/*
 * @Author: hwu
 * @Date: 2021-02-26 09:44:28
 * @Description: file content
 * @LastEditTime: 2024-02-27 14:53:33
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueWechatTitle from 'vue-wechat-title'
// 为了使用自定义主题色，把elementUI全部引入
import ElementUi from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import 'normalize.css/normalize.css'
import './styles/iconfont/iconfont.css'
import './styles/theme/index.css'
import './styles/wh-global.less' // 自定义全局样式（新）

import '@/libs/rem'
import '@/utils/globalUtil'

Vue.use(VueWechatTitle)
Vue.use(ElementUi)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
