import Vue from 'vue'

/**
 * 七牛云图片路径转换
 * @param {*} key 图片id
 * @param {*} width 压缩的图片宽度（宽度固定，高度自适应）
 * @param {*} compress 是否压缩
 * @param {*} radius 是否圆角 （'200'表示200像素， '!50p'表示50%）
 * @returns
 */
Vue.prototype.$transferQnyUrl = (key, width = 400, compress = true, radius = '') => {
  if (!key) {
    return ''
  }
  const domian = process.env.VUE_APP_QNY_LOAD_URL

  // 不需要压缩，就直接返回
  if (!compress) {
    if (radius) {
      return `${domian}${key}?roundPic/radius/${radius}`
    }
    return `${domian}${key}`
  }

  if (radius) {
    return `${domian}${key}?imageView2/0/w/${width}&roundPic/radius/${radius}`
  }

  return `${domian}${key}?imageView2/0/w/${width}`
}
